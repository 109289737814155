import {
  Accordion,
  Center,
  Divider,
  Flex,
  Progress,
  RingProgress,
  SimpleGrid,
  Stack,
  Text,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconClock } from "../../_Icons/CustonIcons";
import { formatTime } from "../../../utilities/HelperFunctions";
import { useEffect } from "react";
import {
  QuestionParentType,
  findQuestionType,
} from "../../../@types/QuestionTypes.d";
import {
  CASEReportQuestionCard,
  MCQReportQuestionCard,
  SubjectiveQuestionCard,
} from "./ReportQuestionCards";
import { PdfViewer } from "../FileUploadBox";

function SingleCard(props: {
  name: string;
  totalMarks: number;
  maxMarks: number;
}) {
  const percentage =
    ((props.totalMarks ?? 0) / (props.maxMarks ?? 1)) * 100 < 0
      ? 0
      : ((props.totalMarks ?? 0) / (props.maxMarks ?? 1)) * 100;
  const isMd = useMediaQuery("(max-width:820px)");
  return (
    <Flex
      justify="space-between"
      style={{
        boxShadow: "0px 0px 4px 0px #00000040",
        borderRadius: "7px",
      }}
      py={25}
      px={10}
    >
      <Text
        fz={isMd ? 14 : 18}
        fw={700}
        style={{
          width: "50%",
        }}
      >
        {props.name}
      </Text>
      <Flex w="45%" align="center">
        <Progress size={16} w={"80%"} value={percentage} color="#4b65f6" />
        <Text fw={700} fz={isMd ? 16 : 18} pl={20}>
          {`${(((props.totalMarks ?? 0) / (props.maxMarks ?? 1)) * 100).toFixed(
            2
          )}%`}
        </Text>
      </Flex>
    </Flex>
  );
}

export function TestReport(props: {
  testReport: TestReport;
  superSections: {
    name: string;
    sections: any[];
  }[];
  totalNumberOfStudents: number;
  isSingleReport: boolean;
  isTestwithOnlyMarks: boolean;
}) {
  const isMd = useMediaQuery("(max-width: 820px)");

  useEffect(() => {
    setTimeout(() => {
      if (typeof window?.MathJax !== "undefined") {
        window.MathJax.typesetClear();
        window.MathJax.typeset();
      }
    }, 500);
  }, [props.superSections]);

  function getSuperSectionsMarks(superSection: any) {
    return superSection.sections.reduce((acc: any, section: any) => {
      return (
        acc +
        section.questions.reduce(
          (acc: any, question: any) => acc + question.totalMarks,
          0
        )
      );
    }, 0);
  }
  function getSectionMarks(section: any) {
    return section.questions.reduce(
      (acc: any, question: any) => acc + question.totalMarks,
      0
    );
  }
  let sectionCount = -1;
  let sectionCountForQuestions = -1;
  return (
    <>
      {!props.isTestwithOnlyMarks && (
        <Stack w="100%">
          <Flex
            justify="space-between"
            direction={isMd ? "column" : "row"}
            gap={isMd ? 25 : 0}
          >
            <Stack
              style={{
                boxShadow: "0px 0px 4px 0px #00000040",
                borderRadius: "10px",
              }}
              w={isMd ? "100%" : "48%"}
            >
              <Stack spacing={1} ml={isMd ? 15 : 30} mt={10}>
                <Text fz={16} fw={700}>
                  Average Score
                </Text>
                <Text color="#AFAFAF" fz={12} fw={500}>
                  See how well your students did to know how ready they are!
                </Text>
              </Stack>
              <Divider color="#E5E7ED" size="md" />
              <Flex>
                <Flex w="50%" justify="center" align="center">
                  <RingProgress
                    size={isMd ? 120 : 120}
                    rootColor="#3174F34D"
                    thickness={isMd ? 10 : 12}
                    sections={[
                      {
                        value:
                          ((props.testReport.totalMarks < 0
                            ? 0
                            : props.testReport.totalMarks) /
                            props.testReport.maxMarks) *
                          100,
                        color: "#4b65f6",
                      },
                    ]}
                    label={
                      <>
                        <Center>
                          <Stack spacing={0} justify="center" align="center">
                            <Text fz={isMd ? 12 : 14}>Marks</Text>
                            <Text fz={isMd ? 10 : 12} fw={700}>
                              {`${
                                props.testReport.totalMarks
                                  ? props.testReport.totalMarks.toFixed(2)
                                  : 0
                              } /
                            ${props.testReport.maxMarks ?? 1}`}
                            </Text>
                          </Stack>
                        </Center>
                      </>
                    }
                  />
                </Flex>
                <Stack
                  w="50%"
                  justify="center"
                  spacing={5}
                  py={30}
                  align="justify"
                >
                  <Text fz={14} fw={400}>
                    Maximum Marks:
                    {props.testReport.maxMarks}
                  </Text>
                  <Text fz={14} fw={400}>
                    Total Average Score Achieved:
                    {props.testReport.totalMarks
                      ? props.testReport.totalMarks.toFixed(2)
                      : 0}
                  </Text>
                </Stack>
              </Flex>
              <Stack spacing={1} mb={30} mx={30}>
                <Flex justify="space-between">
                  <Text mb={6} fw={700}>
                    Percentage
                  </Text>
                  <Text fw={700}>
                    {`${(
                      ((props.testReport.totalMarks ?? 0) /
                        (props.testReport.maxMarks ?? 1)) *
                      100
                    ).toFixed(2)}%`}
                  </Text>
                </Flex>
                <Progress
                  size={16}
                  w={"100%"}
                  value={
                    ((props.testReport.totalMarks <= 0
                      ? 0
                      : props.testReport.totalMarks) /
                      (props.testReport.maxMarks ?? 1)) *
                    100
                  }
                  color="#4b65f6"
                />
              </Stack>
            </Stack>
            <Stack
              style={{
                boxShadow: "0px 0px 4px 0px #00000040",
                borderRadius: "10px",
              }}
              h="100%"
              spacing={1}
              w={isMd ? "100%" : "48%"}
            >
              <Stack spacing={1} ml={isMd ? 15 : 30} mt={10}>
                <Text fz={16} fw={700}>
                  Average Time Taken
                </Text>
                <Text color="#AFAFAF" fz={12} fw={500}>
                  See how long your students take to complete this test!
                </Text>
              </Stack>
              <Divider color="#E5E7ED" size="md" mt={13} />
              <Center w="100%" h="100%">
                <RingProgress
                  size={isMd ? 200 : 200}
                  rootColor="#3174F34D"
                  thickness={isMd ? 10 : 12}
                  sections={[
                    {
                      value:
                        (props.testReport.totalTimeTaken /
                          props.testReport.maxDuration) *
                        100,
                      color: "#4b65f6",
                    },
                  ]}
                  label={
                    <>
                      <Stack      
                        align="center"
                        justify="center"
                        py={isMd ? 20 : 0}
                        h="100%"
                      >
                        <IconClock />
                        <Stack spacing={1} justify="center" align="center">
                          <Text fw={600} fz={24}>
                            {formatTime(props.testReport.totalTimeTaken * 1000)}
                          </Text>
                          <Text color="#7E7E7E" fz={18} fw={500} ta="center">
                            Time
                          </Text>
                        </Stack>
                      </Stack>
                    </>
                  }
                />
              </Center>
            </Stack>
          </Flex>
          <Stack>
            <Text fz={19} fw={700}>
              Average Section Wise Score
            </Text>
            {props.superSections.length > 1 && (
              <Accordion defaultValue="customization">
                {props.superSections.map((superSection, i) => {
                  return (
                    <Stack>
                      <Accordion.Item value={`${i}`}>
                        <Accordion.Control
                          py={0}
                          style={{
                            boxShadow: "0px 0px 4px 0px #00000040",
                            borderRadius: "7px",
                          }}
                        >
                          <SingleCard
                            name={superSection.name}
                            totalMarks={props.testReport.sectionWiseDetails
                              .slice(
                                sectionCount + 1,
                                sectionCount + 1 + superSection.sections.length
                              )
                              .reduce((acc: any, section) => {
                                return acc + section.marksObtained;
                              }, 0)}
                            maxMarks={getSuperSectionsMarks(superSection)}
                          />
                        </Accordion.Control>
                        <Accordion.Panel styles={{}}>
                          <Stack w="100%" spacing={15} mt={20}>
                            {superSection.sections.map((section, j) => {
                              sectionCount++;
                              return (
                                <SingleCard
                                  name={section.name}
                                  totalMarks={
                                    props.testReport.sectionWiseDetails[
                                      sectionCount
                                    ].marksObtained
                                  }
                                  maxMarks={getSectionMarks(section)}
                                />
                              );
                            })}
                          </Stack>
                        </Accordion.Panel>
                      </Accordion.Item>
                    </Stack>
                  );
                })}
              </Accordion>
            )}
            {props.superSections.length === 1 && (
              <Stack w="100%">
                {props.superSections[0].sections.map((section, j) => {
                  return (
                    <SingleCard
                      name={section.name}
                      totalMarks={
                        props.testReport.sectionWiseDetails[j].marksObtained
                      }
                      maxMarks={getSectionMarks(section)}
                    />
                  );
                })}
              </Stack>
            )}
            <Text fz={24} fw={700}>
              Questions
            </Text>
            {props.superSections.map((superSection, i) => {
              return (
                <Stack>
                  {props.superSections.length > 1 && (
                    <Text fz={16} fw={700}>
                      {superSection.name}
                    </Text>
                  )}
                  {superSection.sections.map((section, j) => {
                    sectionCountForQuestions++;
                    return (
                      <Stack>
                        <Flex
                          justify="space-between"
                          style={{
                            boxShadow: "0px 0px 4px 0px #00000040",
                            borderRadius: "7px",
                          }}
                          py={25}
                          pl={10}
                          px={20}
                        >
                          <Text fz={16} fw={700}>
                            {section.name}
                          </Text>
                          <Text>
                            Time Taken |{" "}
                            {formatTime(
                              props.testReport.sectionWiseDetails[
                                sectionCountForQuestions
                              ].timeTaken * 1000
                            )}
                          </Text>
                        </Flex>
                        {section.questions.map(
                          (question: any, index: number) => {
                            switch (
                              findQuestionType(question.type).parentType
                            ) {
                              case QuestionParentType.MCQQ:
                                return (
                                  <MCQReportQuestionCard
                                    number={index + 1}
                                    question={question?.text}
                                    answers={question.answers}
                                    markedCorrect={
                                      props.testReport.sectionWiseDetails[
                                        sectionCountForQuestions
                                      ].questions[index].markedCorrect
                                    }
                                    markedUnattempted={
                                      props.testReport.sectionWiseDetails[
                                        sectionCountForQuestions
                                      ].questions[index].unattempted
                                    }
                                    timeTaken={
                                      props.testReport.sectionWiseDetails[
                                        sectionCountForQuestions
                                      ].questions[index].timeTaken
                                    }
                                    totalNumberOfStudents={
                                      props.totalNumberOfStudents
                                    }
                                    key={index}
                                    markedAnswers={
                                      props.isSingleReport
                                        ? props.testReport.sectionWiseDetails[
                                            sectionCountForQuestions
                                          ].questions[index].options
                                        : question.answers
                                            .map((x: any, i: number) => {
                                              if (x.isCorrect) {
                                                return i;
                                              }
                                              return -1;
                                            })
                                            .filter((x: number) => x !== -1)
                                    }
                                    isSingleReport={props.isSingleReport}
                                    isCorrect={
                                      props.isSingleReport
                                        ? props.testReport.sectionWiseDetails[
                                            sectionCountForQuestions
                                          ].questions[index].isCorrect[0]
                                        : true
                                    }
                                    explaination={question.explaination}
                                  />
                                );
                              case QuestionParentType.SUBQ:
                                return (
                                  <SubjectiveQuestionCard
                                    question={question.text}
                                    answer={question.answer}
                                    number={index + 1}
                                    markedUnattempted={
                                      props.testReport.sectionWiseDetails[
                                        sectionCountForQuestions
                                      ].questions[index].unattempted
                                    }
                                    markedCorrect={
                                      props.testReport.sectionWiseDetails[
                                        sectionCountForQuestions
                                      ].questions[index].markedCorrect
                                    }
                                    totalNumberOfStudents={
                                      props.totalNumberOfStudents
                                    }
                                    timeTaken={
                                      props.testReport.sectionWiseDetails[
                                        sectionCountForQuestions
                                      ].questions[index].timeTaken
                                    }
                                    markedAnswers={
                                      props.testReport.sectionWiseDetails[
                                        sectionCountForQuestions
                                      ].questions[index].answerText
                                    }
                                    isSingleReport={true}
                                    marks={0}
                                    maxMarks={question.totalMarks}
                                    negativeMarks={question.totalNegativeMarks}
                                    explaination={question.explaination}
                                  />
                                );
                              case QuestionParentType.CASEQ:
                                return (
                                  <CASEReportQuestionCard
                                    id={question.id}
                                    questions={question.questions}
                                    caseStudyText={question.caseStudyText}
                                    number={index + 1}
                                    markedUnattempted={
                                      props.testReport.sectionWiseDetails[
                                        sectionCountForQuestions
                                      ].questions[index].unattempted
                                    }
                                    markedCorrect={
                                      props.testReport.sectionWiseDetails[
                                        sectionCountForQuestions
                                      ].questions[index].markedCorrect
                                    }
                                    totalNumberOfStudents={
                                      props.totalNumberOfStudents
                                    }
                                    timeTaken={
                                      props.testReport.sectionWiseDetails[
                                        sectionCountForQuestions
                                      ].questions[index].timeTaken
                                    }
                                    markedAnswers={
                                      props.isSingleReport
                                        ? props.testReport.sectionWiseDetails[
                                            sectionCountForQuestions
                                          ].questions[index].options
                                        : question.questions.map((x: any) => {
                                            const answer = x.answers
                                              .map((x: any, i: number) => {
                                                if (x.isCorrect) {
                                                  return i;
                                                }
                                                return -1;
                                              })
                                              .filter((x: number) => x !== -1);
                                            if (answer.length === 0) {
                                              return -1;
                                            } else {
                                              return answer[0];
                                            }
                                          })
                                    }
                                    isSingleReport={props.isSingleReport}
                                    isCorrect={
                                      props.isSingleReport
                                        ? props.testReport.sectionWiseDetails[
                                            sectionCountForQuestions
                                          ].questions[index].isCorrect
                                        : question.questions.map(() => {
                                            return true;
                                          })
                                    }
                                    explaination={question.explaination}
                                  />
                                );
                            }
                          }
                        )}
                      </Stack>
                    );
                  })}
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      )}
      {props.isTestwithOnlyMarks === true && (
        <Stack w="100%" h="100%">
          <PdfViewer url={props.testReport?.pdfLink} showOptions={true} />
        </Stack>
      )}
    </>
  );
}
