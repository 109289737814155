import {
  Button,
  Card,
  Checkbox,
  CheckboxProps,
  Divider,
  Flex,
  Grid,
  Group,
  NumberInput,
  Stack,
  Text,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconCheck, IconX } from "@tabler/icons";
import { useEffect, useState } from "react";
import { DisplayHtmlText } from "../../../pages/_New/PersonalizedTestQuestions";
import { formatTime } from "../../../utilities/HelperFunctions";
const lettering = ["a", "b", "c", "d", "e", "f", "g", "h"];

function getIcon(val: boolean) {
  const CheckboxIcon: CheckboxProps["icon"] = ({ indeterminate, ...others }) =>
    val ? <IconCheck {...others} /> : <IconX {...others} />;
  return CheckboxIcon;
}

function BottomBar(props: {
  markedCorrect: number;
  markedUnattempted: number;
  totalNumberOfStudents: number;
  timeTaken: number;
  answersComponent: JSX.Element;
  isSingleReport: boolean;
}) {
  const isMd = useMediaQuery(`(max-width: 820px)`);
  const [isShowAnswer, setIsShowAnswer] = useState<boolean>(false);
  useEffect(() => {
    setTimeout(() => {
      if (typeof window?.MathJax !== "undefined") {
        window.MathJax.typesetClear();
        window.MathJax.typeset();
      }
    }, 500);
  }, [isShowAnswer]);
  return (
    <Stack w="100%" mx={-10}>
      <Divider size={2} mt={20} />
      <Flex
        justify={!props.isSingleReport ? "space-between" : "right"}
        align="end"
        direction={isMd ? "column" : "row"}
        gap={isMd ? 15 : 0}
        w="100%"
      >
        <Flex
          c="#737373"
          mt={10}
          px={10}
          w="100%"
          fz={isMd ? 14 : 16}
          justify={isMd ? "space-between" : "left"}
          gap={isMd ? 0 : 20}
        >
          {!props.isSingleReport && (
            <>
              <Text>
                {props.markedCorrect}/{props.totalNumberOfStudents}
                <br />
                Marked Correct
              </Text>
              <Text>
                {props.markedUnattempted}/{props.totalNumberOfStudents}
                <br />
                Unattempted
              </Text>
            </>
          )}
          <Text>
            {formatTime(props.timeTaken * 1000)}
            <br />
            Time Taken
          </Text>
        </Flex>
        {props.isSingleReport && (
          <Button
            onClick={() => setIsShowAnswer(!isShowAnswer)}
            color="blue"
            size={isMd ? "sm" : "lg"}
            bg="#4B65F6"
          >
            {isShowAnswer ? "Hide" : "Show"} Answer
          </Button>
        )}
      </Flex>
      {isShowAnswer && props.isSingleReport && props.answersComponent}
    </Stack>
  );
}

export function MCQReportQuestionCard(props: {
  question: string;
  answers: { text: string; isCorrect: boolean }[];
  number: number;
  markedUnattempted: number;
  markedCorrect: number;
  totalNumberOfStudents: number;
  timeTaken: number;
  markedAnswers: number[];
  isSingleReport: boolean;
  isCorrect: boolean;
  explaination: string;
}) {
  const isMd = useMediaQuery(`(max-width: 820px)`);

  return (
    <>
      <Card
        shadow="0px 0px 4px 0px #00000040"
        style={{ borderRadius: "10px" }}
        withBorder
        w={isMd ? "100%" : "100%"}
      >
        <Stack pl="5%">
          <Grid fw={500} c={"#737373"}>
            <Grid.Col span={"content"}>{props.number}.</Grid.Col>
            <Grid.Col span={11}>
              <Stack>
                <DisplayHtmlText text={props.question} />
                {props.answers.map((x, index) => {
                  return (
                    <Flex key={index}>
                      <Checkbox
                        radius={50}
                        checked={props.markedAnswers.includes(index)}
                        styles={{
                          input: {
                            backgroundColor: "#D9D9D9",
                            borderColor: "#D9D9D9",
                            "&:checked": {
                              backgroundColor: props.isCorrect
                                ? "#14FF00"
                                : "red",
                              borderColor: props.isCorrect ? "#14FF00" : "red",
                            },
                          },
                        }}
                        icon={getIcon(props.isCorrect)}
                      />
                      <DisplayHtmlText text={x.text} />
                    </Flex>
                  );
                })}
              </Stack>
            </Grid.Col>
          </Grid>
          <BottomBar
            isSingleReport={props.isSingleReport}
            markedCorrect={props.markedCorrect}
            markedUnattempted={props.markedUnattempted}
            totalNumberOfStudents={props.totalNumberOfStudents}
            timeTaken={props.timeTaken}
            answersComponent={
              <Stack>
                {props.answers
                  .map((x, index) => {
                    if (x.isCorrect === true)
                      return (
                        <Flex>
                          <Text>
                            {lettering[index]}
                            {")"}{" "}
                          </Text>
                          <DisplayHtmlText text={x.text} />
                        </Flex>
                      );
                    else return null;
                  })
                  .filter((x) => x !== null)}
                <DisplayHtmlText text={props.explaination} />
              </Stack>
            }
          />
        </Stack>
      </Card>
    </>
  );
}
export function SubjectiveQuestionCard(props: {
  question: string;
  answer: string;
  number: number;
  markedUnattempted: number;
  markedCorrect: number;
  totalNumberOfStudents: number;
  timeTaken: number;
  markedAnswers: string;
  isSingleReport: boolean;
  marks: number;
  maxMarks: number;
  negativeMarks: number;
  onMarkChange?: (val: number) => void | undefined;
  explaination: string;
}) {
  const isMd = useMediaQuery(`(max-width: 820px)`);
  return (
    <>
      <Card
        shadow="0px 0px 4px 0px #00000040"
        style={{ borderRadius: "10px" }}
        withBorder
        w={isMd ? "100%" : "100%"}
      >
        <Stack pl="5%">
          <Grid fw={500} c={"#737373"}>
            <Grid.Col span={"content"}>{props.number}.</Grid.Col>
            <Grid.Col span={11}>
              <Stack>
                <DisplayHtmlText text={props.question} />
                <Flex>
                  <Text>Ans.</Text>
                  <DisplayHtmlText text={props.markedAnswers} />
                </Flex>
              </Stack>
            </Grid.Col>
          </Grid>
          {props.onMarkChange && (
            <Flex justify="right" align="center" mr={10} gap={10}>
              <Text fz={isMd ? 16 : 20} fw={500}>
                Update Marks:
              </Text>
              <Flex
                align="center"
                style={{
                  border: "1px solid #D9D9D9",
                  borderRadius: "5px",
                }}
                py={5}
                px={10}
              >
                <NumberInput
                  value={props.marks}
                  precision={2}
                  onChange={(e) => {
                    if (
                      e !== undefined &&
                      e <= props.maxMarks &&
                      e >= props.negativeMarks
                    ) {
                      console.log(e);
                      if (props.onMarkChange) props.onMarkChange(e);
                    }
                  }}
                  min={props.negativeMarks}
                  max={Number(props.maxMarks.toPrecision(2))}
                  style={{
                    width: isMd ? 50 : "55px",
                  }}
                  styles={{
                    input: {
                      fontSize: isMd ? 13 : 16,
                    },
                  }}
                  hideControls
                />

                <span
                  style={{
                    fontSize: isMd ? 30 : "40px",
                    fontWeight: "200",
                  }}
                >
                  /
                </span>
                <span
                  style={{
                    fontSize: isMd ? 13 : 16,
                  }}
                >
                  {props.maxMarks.toPrecision(2)}
                </span>
              </Flex>
            </Flex>
          )}
          <BottomBar
            isSingleReport={props.isSingleReport}
            markedCorrect={props.markedCorrect}
            markedUnattempted={props.markedUnattempted}
            totalNumberOfStudents={props.totalNumberOfStudents}
            timeTaken={props.timeTaken}
            answersComponent={
              <Stack>
                {
                  <Flex>
                    <DisplayHtmlText text={props.answer} />
                    <DisplayHtmlText text={props.explaination} />
                  </Flex>
                }
              </Stack>
            }
          />
        </Stack>
      </Card>
    </>
  );
}

export function CASEReportQuestionCard(props: {
  id: string;
  questions: McqQuestion[];
  caseStudyText: string;
  number: number;
  markedUnattempted: number;
  markedCorrect: number;
  totalNumberOfStudents: number;
  timeTaken: number;
  markedAnswers: number[];
  isSingleReport: boolean;
  isCorrect: boolean[];
  explaination: string;
}) {
  const isMd = useMediaQuery(`(max-width: 820px)`);
  return (
    <>
      <Card
        shadow="0px 0px 4px 0px #00000040"
        style={{ borderRadius: "10px" }}
        withBorder
        w={isMd ? "100%" : "100%"}
      >
        <Stack pl={"5%"}>
          <Grid fw={500} c={"#737373"}>
            <Grid.Col span={"content"}>{props.number}.</Grid.Col>
            <Grid.Col
              span={11}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Stack w="100%">
                <DisplayHtmlText text={props.caseStudyText} />
                {props.questions.map((y, i) => {
                  return (
                    <Stack w="100%">
                      <Flex>
                        <Text>{`${i + 1}.`}</Text>
                        <DisplayHtmlText text={y.text} />
                      </Flex>
                      {y.answers.map((x, index) => {
                        return (
                          <Group key={index} w="100%">
                            <Checkbox
                              radius={50}
                              checked={
                                props.markedAnswers[i] > -1
                                  ? props.markedAnswers[i] === index
                                  : false
                              }
                              styles={{
                                input: {
                                  backgroundColor: "#D9D9D9",
                                  borderColor: "#D9D9D9",
                                  "&:checked": {
                                    backgroundColor: props.isCorrect[i]
                                      ? "#14FF00"
                                      : "red",
                                    borderColor: props.isCorrect[i]
                                      ? "#14FF00"
                                      : "red",
                                  },
                                },
                              }}
                              w="10px"
                              icon={getIcon(props.isCorrect[i])}
                            />
                            <DisplayHtmlText text={x.text} />
                          </Group>
                        );
                      })}
                    </Stack>
                  );
                })}
              </Stack>
            </Grid.Col>
          </Grid>
          <BottomBar
            markedCorrect={props.markedCorrect}
            markedUnattempted={props.markedUnattempted}
            totalNumberOfStudents={props.totalNumberOfStudents}
            timeTaken={props.timeTaken}
            isSingleReport={props.isSingleReport}
            answersComponent={
              <Stack>
                {props.questions.map((y, i) => {
                  return (
                    <Stack>
                      {y.answers
                        .map((x, index) => {
                          if (x.isCorrect === true)
                            return (
                              <Flex>
                                <Text>
                                  {i + 1}. {lettering[index]}
                                  {")"}
                                </Text>

                                <DisplayHtmlText text={x.text} />
                              </Flex>
                            );
                          return null;
                        })
                        .filter((x) => {
                          return x !== null;
                        })}
                      <DisplayHtmlText text={y.explaination} />
                    </Stack>
                  );
                })}
              </Stack>
            }
          />
        </Stack>
      </Card>
    </>
  );
}
