import React, { useState } from "react";

const Keypad = ({ onKeyPress }: any) => {
  const [input, setInput] = useState("");

  const handleKeyPress = (value: any) => {
    setInput(input + value);
    if (onKeyPress) {
      onKeyPress(input + value);
    }
  };

  const handleDelete = () => {
    setInput(input.slice(0, -1));
    if (onKeyPress) {
      onKeyPress(input.slice(0, -1));
    }
  };

  const buttonStyle = {
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    backgroundColor: "#f0f0f0",
    cursor: "pointer",
    fontSize: "16px",
  };

  const deleteButtonStyle = {
    ...buttonStyle,
    color: "red",
  };

  return (
    <div
      style={{
        border: "2px solid #ccc",
        padding: "15px",
        borderRadius: "5px",
        width: "160px",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "5px",
          }}
        >
          <button style={buttonStyle} onClick={() => handleKeyPress("1")}>
            1
          </button>
          <button style={buttonStyle} onClick={() => handleKeyPress("2")}>
            2
          </button>
          <button style={buttonStyle} onClick={() => handleKeyPress("3")}>
            3
          </button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "5px",
          }}
        >
          <button style={buttonStyle} onClick={() => handleKeyPress("4")}>
            4
          </button>
          <button style={buttonStyle} onClick={() => handleKeyPress("5")}>
            5
          </button>
          <button style={buttonStyle} onClick={() => handleKeyPress("6")}>
            6
          </button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "5px",
          }}
        >
          <button style={buttonStyle} onClick={() => handleKeyPress("7")}>
            7
          </button>
          <button style={buttonStyle} onClick={() => handleKeyPress("8")}>
            8
          </button>
          <button style={buttonStyle} onClick={() => handleKeyPress("9")}>
            9
          </button>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <button style={deleteButtonStyle} onClick={handleDelete}>
            Delete
          </button>
          <button style={buttonStyle} onClick={() => handleKeyPress("0")}>
            0
          </button>
        </div>
      </div>
    </div>
  );
};

export default Keypad;
