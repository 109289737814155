import { Carousel, Embla, useAnimationOffsetEffect } from "@mantine/carousel";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Modal,
  ScrollArea,
  Stack,
  Text,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  QuestionParentType,
  findQuestionType,
} from "../../../@types/QuestionTypes.d";
import { RootState } from "../../../store/ReduxStore";
import { formatTimewithSecondsFormatting } from "../../../utilities/HelperFunctions";
import {
  CaseBasedQuestionCard,
  MCQuestionCard,
  QuestionCard,
} from "./StudentTest";
import { useNavigate } from "react-router-dom";
import { InstructionsPointsPage } from "./SingleParentTest";

enum QuestionStatus {
  MARKFORREVIEW,
  ANSWERED,
  NOTANSWERED,
  NOTVISITED,
  MARKFORREVIEWANSWERED,
}

function getQuestionStatusImage(status: QuestionStatus) {
  switch (status) {
    case QuestionStatus.ANSWERED:
      return require("../../../assets/Rectangle 3737.png");
    case QuestionStatus.NOTANSWERED:
      return require("../../../assets/Rectangle 3736.png");
    case QuestionStatus.NOTVISITED:
      return require("../../../assets/Rectangle 3735.png");
    case QuestionStatus.MARKFORREVIEW:
      return require("../../../assets/Ellipse 847.png");
    case QuestionStatus.MARKFORREVIEWANSWERED:
      return require("../../../assets/Group 40815.png");
  }
}
export function FillAnswersPage(props: {
  testDetails: FullTest;
  setMCQAnswer: (data: {
    question: McqQuestion;
    option: number | null;
    answerText?: string;
  }) => void;
  setSubjectiveAnswer: (questionId: string, answerText: string) => void;
  onNextButtonClick: (questionTimeSpent: any) => void;
  setCaseAnswer: (data: {
    questionId: string;
    option?: number;
    isCorrect?: boolean;
    questionIndex?: number;
    answerText?: string;
  }) => void;
  mcqAnswers: MCQTestAnswer[];
  subjectiveAnswers: SubjectiveTestAnswer[];
  caseStudyAnswers: CasestudyAnswers[];
  isNav: (val: boolean) => void;
}) {
  const isMd = useMediaQuery(`(max-width: 968px)`);
  const myMap: Map<
    string,
    CaseBasedQuestion | McqQuestion | SubjectiveQuestion
  > = new Map();
  props.testDetails.questions.map((x) => {
    myMap.set(x._id, x);
  });
  props.testDetails.casebasedquestions.map((x) => {
    myMap.set(x._id, x);
  });
  props.testDetails.subjectiveQuestions.map((x) => {
    myMap.set(x._id, x);
  });

  const [timer, setTimer] = useState(
    props.testDetails.isNextSuperSectionClickablebeforeTime
      ? parseInt(
          props.testDetails.duration !== null
            ? props.testDetails.duration
            : "0",
          10
        )
      : parseInt(props.testDetails.superSections[0].totalTime)
  );

  // const [isOptionSelected, setIsOptionSelected] = useState<boolean>(false);
  const [questionTimeSpent, setQuestionTimeSpent] = useState<{
    [key: string]: number;
  }>({});
  const [section1, setSection1] = useState<
    {
      _id: string;
      name: string;
      questions: {
        questionId: string;
        status: QuestionStatus;
        questionIndex: string;
      }[];
    }[]
  >([]);
  const [isInstructionsModalOpen, setIsInstructionsModalOpen] =
    useState<boolean>(false);
  const [selectedQuestionDetails, setSelectedQuestionDetails] = useState<{
    sectionIndex: number;
    questionIndex: number;
    selectedQuestionId: string | null;
  }>({
    sectionIndex: 0,
    questionIndex: 0,
    selectedQuestionId:
      props.testDetails.sections.length > 0
        ? props.testDetails.sections[0].questions[0]
        : null,
  });
  const [selectedSections, setSelectedSections] = useState<
    TestSectionWithQuestionsIds[]
  >(
    props.testDetails.superSections.length > 0
      ? props.testDetails.sections.filter((section) =>
          props.testDetails.superSections[0].sections.includes(section._id)
        )
      : props.testDetails.sections
  );
  const [selectedSuperSection, setSelectedSuperSection] = useState<any>(
    props.testDetails.superSections.length > 0
      ? { ...props.testDetails.superSections[0], index: 0 }
      : []
  );
  const [embla, setEmbla] = useState<Embla | null>(null);
  const [isSubmitTestClicked, setIsSubmitTestClicked] =
    useState<boolean>(false);
  const TRANSITION_DURATION = 200;
  useAnimationOffsetEffect(embla, TRANSITION_DURATION);

  useEffect(() => {
    if (props.testDetails.duration !== null) {
      const interval = setInterval(() => {
        if (timer > 0) {
          setTimer((prevTimer) => prevTimer - 1);
        } else {
          clearInterval(interval);
          if (timer === 0) {
            if (
              props.testDetails.isNextSuperSectionClickablebeforeTime === true
            )
              props.onNextButtonClick(questionTimeSpent);
            else {
              console.log(props.testDetails.superSections);
              console.log(selectedSuperSection);
              if (
                props.testDetails.superSections.length >
                selectedSuperSection.index + 1
              ) {
                setSelectedSuperSection({
                  ...props.testDetails.superSections[
                    selectedSuperSection.index + 1
                  ],
                  index: selectedSuperSection.index + 1,
                });
                setTimer(
                  parseInt(
                    props.testDetails.superSections[
                      selectedSuperSection.index + 1
                    ].totalTime
                  )
                );
              } else {
                props.onNextButtonClick(questionTimeSpent);
              }
            }
          }
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timer, props.testDetails]);

  function getQuestionStatus(
    onSaveNextClick: boolean,
    isOptionSelected: boolean
  ) {
    if (onSaveNextClick) {
      return isOptionSelected
        ? QuestionStatus.ANSWERED
        : QuestionStatus.NOTANSWERED;
    } else {
      const currentQuestion =
        section1[selectedQuestionDetails.sectionIndex].questions[
          selectedQuestionDetails.questionIndex
        ];
      if (isOptionSelected) {
        return QuestionStatus.MARKFORREVIEWANSWERED;
      } else {
        return currentQuestion.status === QuestionStatus.MARKFORREVIEW
          ? QuestionStatus.NOTVISITED
          : QuestionStatus.MARKFORREVIEW;
      }
    }
  }

  const onNextButtonClick = (
    onSaveNextClick: boolean,
    isOptionSelected: boolean
  ) => {
    const currentSection =
      selectedSections[selectedQuestionDetails.sectionIndex];
    const nextQuestionIndex = selectedQuestionDetails.questionIndex + 1;
    const selectedSection = section1.findIndex(
      (x) => x._id === currentSection._id
    );
    setSection1((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[selectedSection].questions[
        selectedQuestionDetails.questionIndex
      ].status = getQuestionStatus(onSaveNextClick, isOptionSelected);
      return updatedSections;
    });

    if (nextQuestionIndex < currentSection.questions.length) {
      setSelectedQuestionDetails((prevIndex) => ({
        sectionIndex: prevIndex.sectionIndex,
        questionIndex: nextQuestionIndex,
        selectedQuestionId: currentSection.questions[nextQuestionIndex],
      }));
    } else {
      if (selectedQuestionDetails.sectionIndex + 1 < selectedSections.length) {
        setSelectedQuestionDetails((prevIndex) => ({
          sectionIndex: prevIndex.sectionIndex + 1,
          questionIndex: 0,
          selectedQuestionId:
            selectedSections[selectedQuestionDetails.sectionIndex + 1]
              .questions[0],
        }));
      }
    }
  };

  useEffect(() => {
    const sections: any = [];
    props.testDetails.sections.map((x) => {
      const questions = x.questions.map((questionId, questionIndex) => {
        return { questionId, status: QuestionStatus.NOTVISITED, questionIndex };
      });
      sections.push({ _id: x._id, name: x.name, questions });
    });
    setSection1(sections);
  }, [props.testDetails]);

  const rightSelectedSection = section1.find((x) => {
    return (
      x._id ===
      (selectedSections[selectedQuestionDetails.sectionIndex]
        ? selectedSections[selectedQuestionDetails.sectionIndex]._id
        : "")
    );
  });
  useEffect(() => {
    setSelectedSections(
      props.testDetails.superSections.length > 0
        ? props.testDetails.sections.filter((section) =>
            selectedSuperSection.sections.includes(section._id)
          )
        : props.testDetails.sections
    );
  }, [selectedSuperSection]);

  useEffect(() => {
    if (selectedSections.length > 0) {
      setSelectedQuestionDetails({
        sectionIndex: 0,
        questionIndex: 0,
        selectedQuestionId: selectedSections[0].questions[0],
      });
    }
  }, [selectedSections]);
  useEffect(() => {
    if (selectedQuestionDetails.selectedQuestionId !== null) {
      const interval = setInterval(() => {
        setQuestionTimeSpent((prevTimeSpent) => {
          const updatedTimeSpent = { ...prevTimeSpent };
          if (!updatedTimeSpent[selectedQuestionDetails.selectedQuestionId!!]) {
            updatedTimeSpent[selectedQuestionDetails.selectedQuestionId!!] = 0;
          }
          updatedTimeSpent[selectedQuestionDetails.selectedQuestionId!!] += 0.1;
          return updatedTimeSpent;
        });
      }, 100);

      return () => clearInterval(interval);
    }
  }, [selectedQuestionDetails.selectedQuestionId]);

  const question = myMap.get(selectedQuestionDetails.selectedQuestionId ?? "");
  const navigate = useNavigate();
  function handleRefreshState(e: any) {
    e.preventDefault();
    window.confirm("Are you sure you want to leave this page?");
    navigate(1);
  }
  useEffect(() => {
    window.addEventListener("beforeunload", handleRefreshState);
    window.addEventListener("popstate", handleRefreshState);
    props.isNav(false);
    return () => {
      window.removeEventListener("beforeunload", handleRefreshState);
      window.removeEventListener("popstate", handleRefreshState);
      props.isNav(true);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (typeof window?.MathJax !== "undefined") {
        window.MathJax.typesetClear();
        window.MathJax.typeset();
      }
    }, 500);
  }, [props.testDetails]);

  const instituteDetails = useSelector<RootState, InstituteDetails | null>(
    (state) => state.instituteDetailsSlice.instituteDetails
  );

  useEffect(() => {
    setTimeout(() => {
      if (typeof window?.MathJax !== "undefined") {
        window.MathJax.typesetClear();
        window.MathJax.typeset();
      }
    }, 500);
  }, [props.testDetails, question]);

  return (
    <>
      {!isMd && (
        <Flex w="100%" align="center" py={5} bg="#F4F6FE" mt={-2}>
          <img
            src={instituteDetails?.iconUrl}
            alt="logo"
            style={{ height: "50px" }}
          />
          <Text fz={20} fw={700} ml={10}>
            {instituteDetails?.name}
          </Text>
        </Flex>
      )}
      <Flex
        justify={isMd ? "center" : "space-between"}
        direction={isMd ? "column" : "row"}
      >
        <Stack
          w={"100%"}
          mb={isMd ? 4 : 0}
          style={{
            maxHeight: isMd ? "none" : "calc(100vh - 80px)",
          }}
        >
          <ScrollArea h="100%">
            <Box>
              <Flex justify="space-between">
                <Flex style={{ cursor: "pointer" }}>
                  <Text fz={16} fw={700}>
                    Section
                  </Text>
                  <img
                    src={require("../../../assets/instructions.png")}
                    style={{ marginLeft: "10px" }}
                    alt=""
                    onClick={() => {
                      setIsInstructionsModalOpen(true);
                    }}
                  />
                  <Text
                    color="blue"
                    ml={4}
                    onClick={() => {
                      setIsInstructionsModalOpen(true);
                    }}
                  >
                    Instructions
                  </Text>
                </Flex>
                {props.testDetails.duration !== null && (
                  <Text fz={16} fw={700}>
                    Time Left: {formatTimewithSecondsFormatting(timer)}
                  </Text>
                )}
              </Flex>
              <Divider size={2} mb={5} mt={8} />
            </Box>
            <Stack>
              {props.testDetails && props.testDetails.pdfLink === null && (
                <Stack>
                  {props.testDetails.superSections.length > 0 && (
                    <Flex ml={isMd ? 8 : 10} mt={-7} gap={10}>
                      {props.testDetails.superSections.map(
                        (superSection, i) => {
                          return (
                            <Text
                              key={superSection._id}
                              fz={14}
                              fw={700}
                              p={8}
                              bg={
                                selectedSuperSection._id === superSection._id
                                  ? "#4E85C5"
                                  : "rgba(78, 133, 197, 0.5)"
                              }
                              color={"white"}
                              onClick={() => {
                                if (
                                  props.testDetails
                                    .isNextSuperSectionClickablebeforeTime ===
                                  true
                                )
                                  setSelectedSuperSection({
                                    ...superSection,
                                    index: i,
                                  });
                                else {
                                  if (selectedSuperSection.index === i) return;
                                  else {
                                    showNotification({
                                      message:
                                        "You can't change the section before the time is over",
                                    });
                                  }
                                }
                              }}
                              style={{ cursor: "pointer", zIndex: 99 }}
                            >
                              {superSection.name}
                            </Text>
                          );
                        }
                      )}
                    </Flex>
                  )}
                  <Flex
                    justify="space-between"
                    ml={isMd ? 8 : 1}
                    mt={-7}
                    direction={isMd ? "column" : "row"}
                    align="end"
                  >
                    <Flex gap={10} w={isMd ? "100%" : "70%"}>
                      <Carousel
                        getEmblaApi={setEmbla}
                        slideGap={10}
                        align="start"
                        w={"100%"}
                        py={"4%"}
                        // loop
                        nextControlIcon={
                          <Box
                            w={"100%"}
                            h={"100%"}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "4px",
                            }}
                          >
                            <IconChevronRight
                              size={60}
                              stroke={1}
                              color="#747474"
                            />
                          </Box>
                        }
                        previousControlIcon={
                          <Box
                            w={"100%"}
                            h={"100%"}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "4px",
                              // border: "red solid 2px",
                            }}
                          >
                            <IconChevronLeft
                              size={60}
                              stroke={1}
                              color="#747474"
                            />
                          </Box>
                        }
                        styles={{
                          root: {
                            maxWidth: "100%",
                            margin: 0,
                          },
                          controls: {
                            top: 0,
                            height: "100%",
                            padding: "0px",
                            margin: "0px",
                            // border: "red solid 1px",
                          },
                          control: {
                            background: "transparent",
                            // border: "none",
                            boxShadow: "none",
                            height: "200px",
                            padding: 0,
                            "&[data-inactive]": {
                              opacity: 0,
                              cursor: "default",
                            },
                            width: "10px",
                          },
                          indicator: {
                            width: 8,
                            height: 8,
                            backgroundColor: "red",
                            // border: "red solid 1px",
                          },
                          indicators: {
                            top: "110%",
                          },
                        }}
                        m={0}
                        slideSize="auto"
                        px={30}
                      >
                        {selectedSections.map((section, index) => (
                          <Carousel.Slide>
                            <Text
                              key={index}
                              fz={14}
                              fw={700}
                              p={8}
                              bg={
                                selectedQuestionDetails.sectionIndex === index
                                  ? "#4E85C5"
                                  : "rgba(78, 133, 197, 0.5)"
                              }
                              color={"white"}
                              onClick={() => {
                                setSelectedQuestionDetails({
                                  sectionIndex: index,
                                  questionIndex: 0,
                                  selectedQuestionId:
                                    selectedSections[index].questions[0],
                                });
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {section.name}
                            </Text>
                          </Carousel.Slide>
                        ))}
                      </Carousel>
                    </Flex>
                    {question &&
                      findQuestionType(question.type).parentType !==
                        QuestionParentType.CASEQ && (
                        <Text fz={14} fw={700}>
                          Mark(s) for correct answer {question?.totalMarks}
                          {question?.totalNegativeMarks !== 0 && (
                            <span>
                              | Negative Marks -{question?.totalNegativeMarks}
                            </span>
                          )}
                        </Text>
                      )}
                  </Flex>
                  <Divider size={2} mb={10} mt={-16} />
                  {question && (
                    <>
                      {findQuestionType(question.type).parentType ===
                        QuestionParentType.MCQQ && (
                        <MCQuestionCard
                          question={question as McqQuestion}
                          number={selectedQuestionDetails.questionIndex}
                          onAnwerChange={(option) => {
                            props.setMCQAnswer({
                              question: question as McqQuestion,
                              option: option,
                            });
                          }}
                          key={question._id}
                          onOptionSelect={(isSelected) => {
                            // setIsOptionSelected(isSelected);
                          }}
                          toggleMarkedForReview={(val) => {
                            onNextButtonClick(false, val);
                          }}
                          onNextButtonClick={(val) => {
                            onNextButtonClick(true, val);
                          }}
                          mcqAnswers={props.mcqAnswers}
                        />
                      )}
                      {findQuestionType(question.type).parentType ===
                        QuestionParentType.SUBQ && (
                        <QuestionCard
                          question={question as SubjectiveQuestion}
                          number={selectedQuestionDetails.questionIndex}
                          onAnwerChange={(answerText) => {
                            props.setSubjectiveAnswer(question._id, answerText);
                          }}
                          key={question._id}
                          onOptionSelect={(isSelected) => {
                            // setIsOptionSelected(isSelected);
                          }}
                          toggleMarkedForReview={(val) =>
                            onNextButtonClick(false, val)
                          }
                          onNextButtonClick={(val) =>
                            onNextButtonClick(true, val)
                          }
                          subjectiveAnswers={props.subjectiveAnswers}
                        />
                      )}
                      {findQuestionType(question.type).parentType ===
                        QuestionParentType.CASEQ && (
                        <CaseBasedQuestionCard
                          casebasedquestion={question as CaseBasedQuestion}
                          onAnwerChange={(isCorrect, option, questionIndex) => {
                            props.setCaseAnswer({
                              questionId: question._id,
                              option,
                              isCorrect,
                              questionIndex,
                            });
                          }}
                          key={question._id}
                          number={selectedQuestionDetails.questionIndex}
                          _id={question._id}
                          onOptionSelect={(isSelected) => {
                            // setIsOptionSelected(isSelected);
                          }}
                          toggleMarkedForReview={(val) =>
                            onNextButtonClick(false, val)
                          }
                          onNextButtonClick={(val) =>
                            onNextButtonClick(true, val)
                          }
                          caseStudyAnswers={props.caseStudyAnswers}
                        />
                      )}
                    </>
                  )}
                </Stack>
              )}
            </Stack>
          </ScrollArea>
        </Stack>
        <Stack
          mt={isMd ? 10 : 0}
          ml={isMd ? 0 : 10}
          style={{
            border: "1px solid #000000",
            maxHeight: isMd ? "none" : "calc(100vh - 80px)",
          }}
        >
          <ScrollArea h="100%">
            <Flex ml={20} mt={15}>
              <Flex>
                <img
                  src={require("../../../assets/questionstatus3.png")}
                  height="32px"
                  width="32px"
                  alt="Question status 1"
                />
                <Text mt={6} fz={10} fw={400} ml={5}>
                  Answered
                </Text>
              </Flex>
              <Flex ml={20}>
                <img
                  src={require("../../../assets/questionstatus2.png")}
                  height="32px"
                  width="32px"
                  alt="Question status 2"
                />
                <Text mt={6} fz={10} fw={400} ml={5}>
                  Not Answered
                </Text>
              </Flex>
            </Flex>
            <Flex ml={20} mt={15}>
              <Flex>
                <img
                  src={require("../../../assets/questionstatus1.png")}
                  height="32px"
                  width="32px"
                  alt="Question status 3"
                />
                <Text mt={6} fz={10} fw={400} ml={5}>
                  Not Visited
                </Text>
              </Flex>
              <Flex ml={20}>
                <img
                  src={require("../../../assets/questionstatus4.png")}
                  height="32px"
                  width="32px"
                  alt="Question status 4"
                />
                <Text mt={6} fz={10} fw={400} ml={5}>
                  Marked for Review
                </Text>
              </Flex>
            </Flex>
            <Flex ml={20} mt={15} mb={10}>
              <Flex>
                <img
                  src={require("../../../assets/questionstatus5.png")}
                  height="32px"
                  width="32px"
                  alt="Question status 5"
                />
                <Text mt={6} fz={10} fw={400} ml={5}>
                  Answered & Marked for Review (will be considered for
                  evaluation)
                </Text>
              </Flex>
            </Flex>
            {rightSelectedSection &&
              rightSelectedSection.questions.map((question, i) => {
                if (i % 4 === 0) {
                  const groupQuestions = rightSelectedSection.questions.slice(
                    i,
                    i + 4
                  );
                  return (
                    <Flex ml={15} mt={15} key={i}>
                      {groupQuestions.map((question, j) => (
                        <Center>
                          <Text
                            key={j}
                            ml={10}
                            mb={10}
                            onClick={() => {
                              setSelectedQuestionDetails({
                                questionIndex: j + i,
                                selectedQuestionId: question.questionId,
                                sectionIndex:
                                  selectedQuestionDetails.sectionIndex,
                              });
                            }}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <div style={{ position: "relative" }}>
                              <img
                                src={getQuestionStatusImage(question.status)}
                                height="48px"
                                width="48px"
                                alt={question.status.toString()}
                                style={{
                                  margin: "auto",
                                  verticalAlign: "middle",
                                  display: "block",
                                }}
                              />
                              <span
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  fontWeight: "bold",
                                  color: "white",
                                }}
                              >
                                {i + j + 1}
                              </span>
                            </div>
                          </Text>
                        </Center>
                      ))}
                    </Flex>
                  );
                }
                return null;
              })}
            <Divider size={"sm"} style={{ border: "1px solid #000000" }} />
            <Flex
              justify={"flex-end"}
              style={{
                bottom: isMd ? 60 : 0,
                width: "100%",
                height: "80px",
              }}
              py={10}
            >
              <Button
                onClick={() => {
                  setIsSubmitTestClicked(true);
                }}
                style={{
                  backgroundColor: "#38AAE9",
                }}
                mt={20}
                mr={10}
              >
                Submit
              </Button>
            </Flex>
          </ScrollArea>
        </Stack>
      </Flex>
      <Modal
        opened={isInstructionsModalOpen}
        onClose={() => {
          setIsInstructionsModalOpen(false);
        }}
        title="Instructions"
        styles={{
          title: {
            fontSize: "20px",
            fontWeight: 600,
          },
        }}
        centered
      >
        <InstructionsPointsPage testDetails={props.testDetails} />
      </Modal>
      <Modal
        opened={isSubmitTestClicked}
        onClose={() => {
          setIsSubmitTestClicked(false);
        }}
        title="Submit Test"
        styles={{
          title: {
            fontSize: "20px",
            fontWeight: 600,
          },
        }}
        centered
      >
        <Text fz={20}>Are You sure want to submit the test?</Text>
        <Flex justify="right" w="100%">
          <Button
            onClick={() => {
              setIsSubmitTestClicked(false);
              props.onNextButtonClick(questionTimeSpent);
            }}
            mt={10}
            bg="#3174F3"
            size="md"
          >
            Submit
          </Button>
        </Flex>
      </Modal>
    </>
  );
}
