import { Box, Flex, Stack, Text } from "@mantine/core";
import { fetchFullTest } from "../../../features/test/TestSlice";
import { htmlToPdf } from "../../../features/htmlToPDf/htmlToPDf";
import ReactDOMServer from "react-dom/server";
import { GetUser } from "../../../utilities/LocalstorageUtility";
import { GetAllInfoForInstitute } from "../../../_parentsApp/features/instituteSlice";
import { Mixpanel } from "../../../utilities/Mixpanel/MixpanelHelper";
import { WebAppEvents } from "../../../utilities/Mixpanel/AnalyticeEventWebApp";
import { User1 } from "../../../@types/User";
import { QuestionType } from "../../../@types/QuestionTypes.d";
import { createNewSectionTypeHtmlFromTestData } from "./NewTeacherTest";
import { FileUpload } from "../../../features/fileUpload/FileUpload";
import useParentCommunication from "../../../hooks/useParentCommunication";

function createHtmlFromTestData(
  test: FullTest,
  name: string,
  iconUrl: string,
  subHeading: string | null,
  banner: string | null,
  subscriptionModelType: string,
  showInstructions: boolean,
  comicSanFont: boolean,
  inlineContent: boolean
) {
  const lettering = ["a", "b", "c", "d", "e"];
  const instructions = [];
  const mcqQuestions = [];
  const longQuestions = [];
  const shortQuestions = [];
  const caseBasedQuestions = [];
  let lastQuestionNo = 1;
  instructions.push(
    `The Test paper has ${test.maxQuestions} questions in total.`
  );
  instructions.push(
    `Marks are indicated against each question. Each question carry equal marks.`
  );
  if (test.questionType === QuestionType.AllQues.type) {
    mcqQuestions.push(...test.questions);
    const shortAnsQuestions = test.subjectiveQuestions.filter(
      (x) =>
        x.type === QuestionType.ShortQues.type ||
        x.type === QuestionType.FillQues.type
    );
    const longAnsQuestions = test.subjectiveQuestions.filter(
      (x) => x.type === QuestionType.LongQues.type
    );
    longQuestions.push(...longAnsQuestions);
    shortQuestions.push(...shortAnsQuestions);
    caseBasedQuestions.push(...test.casebasedquestions);

    if (test.questions.length > 0) {
      instructions.push(
        `Questions From ${lastQuestionNo} to ${
          lastQuestionNo + test.questions.length - 1
        } are Objective Type Questions.`
      );
      lastQuestionNo += test.questions.length;
    }
    if (shortAnsQuestions.length > 0) {
      instructions.push(
        `Questions From ${lastQuestionNo} to ${
          lastQuestionNo + shortAnsQuestions.length - 1
        } are Short Answer Type Questions.`
      );
      lastQuestionNo += shortAnsQuestions.length;
    }
    if (longAnsQuestions.length > 0) {
      instructions.push(
        `Questions From ${lastQuestionNo} to ${
          lastQuestionNo + longAnsQuestions.length - 1
        } are Long Answer Type Questions.`
      );
      lastQuestionNo += longAnsQuestions.length;
    }
    if (caseBasedQuestions.length > 0) {
      instructions.push(
        `Questions From ${lastQuestionNo} to ${
          lastQuestionNo + caseBasedQuestions.length - 1
        } are Case Based Answer Type Questions.`
      );
      lastQuestionNo += caseBasedQuestions.length;
    }
    instructions.push(
      `Ensure that your answers are legible and properly marked.`
    );
  }
  instructions.push(
    `Follow the specified procedure for submitting your answer sheets or online responses.`
  );
  if (test.questionType === QuestionType.McqQues.type) {
    mcqQuestions.push(...test.questions);
    instructions.push(
      `Ensure that your answers are legible and properly marked.`
    );
  }
  if (test.questionType === QuestionType.LongQues.type) {
    const longAnsQuestions = test.subjectiveQuestions.filter(
      (x) => x.type === QuestionType.LongQues.type
    );
    longQuestions.push(...longAnsQuestions);
    instructions.push(`Ensure that your answers are legible.`);
  }
  if (test.questionType === QuestionType.ShortQues.type) {
    const shortAnsQuestions = test.subjectiveQuestions.filter(
      (x) => x.type === QuestionType.ShortQues.type
    );
    shortQuestions.push(...shortAnsQuestions);
    instructions.push(`Ensure that your answers are legible.`);
  }
  return (
    <>
      <html>
        <head>
          <style>
            @import
            url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,600;1,800&display=swap');
          </style>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin=""
          />
          {comicSanFont && (
            <>
              <link
                href="https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,400;1,700&display=swap"
                rel="stylesheet"
              />

              <link
                href="https://fonts.googleapis.com/css2?family=Courgette&display=swap"
                rel="stylesheet"
              ></link>
            </>
          )}

          <script
            id="MathJax-script"
            defer
            src="https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-chtml.js"
          ></script>
        </head>
        <body
          style={{
            fontFamily: comicSanFont
              ? "Comic Neue, sans-serif"
              : "Nunito, sans-serif",
            whiteSpace: "pre-line",
          }}
        >
          {!inlineContent && (
            <>
              {subscriptionModelType === "PREMIUM" && banner !== null && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "right",
                  }}
                >
                  <img width={200} src={banner}></img>
                </div>
              )}
              <p
                style={{
                  marginTop: 10,
                  fontWeight: 700,
                  fontSize: 30,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {banner === null && <img width={60} src={iconUrl}></img>}
                <span
                  style={{
                    marginRight: 10,
                    marginLeft: 10,
                    borderBottom: "1px solid black",
                  }}
                >
                  {name.toUpperCase()}
                </span>
              </p>
              {subHeading !== null && (
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 400,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: -40,
                    color: "gray",
                  }}
                >
                  {subHeading}
                </p>
              )}
              {/* {subscriptionModelType !== "PREMIUM" && (
            <p
              style={{
                fontSize: 16,
                fontWeight: 400,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: -25,
                color: "blue",
              }}
            >
              Contact us at: www.vignam.in
            </p>
          )} */}

              <p
                style={{
                  fontSize: 22,
                  fontWeight: 700,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "5px 0px",
                }}
              >
                {test.name.toUpperCase()}
              </p>
            </>
          )}
          {inlineContent && (
            <div
              style={{
                marginTop: "-17px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: 25,
                      fontWeight: 800,
                      borderBottom: "2px solid black",
                    }}
                  >
                    {name.toUpperCase()}
                  </p>
                  {subHeading !== null && (
                    <span
                      style={{
                        marginTop: "-20px",
                        fontSize: 20,
                        fontFamily: "Courgette, cursive",
                      }}
                    >
                      {subHeading}
                    </span>
                  )}
                </div>

                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  {test.name.toUpperCase()}
                </p>
              </div>
              <div
                style={{
                  background: "black",
                  height: "1px",
                  width: "100%",
                  margin: "10px 0px",
                }}
              ></div>
            </div>
          )}
          {showInstructions && (
            <Stack
              style={{
                border: "#4B65F6 solid 2px",
                borderRadius: "12px",
                padding: "10px",
              }}
              px={20}
              pb={10}
              pt={15}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: "50%",
                    fontSize: 21,
                    fontWeight: 700,
                  }}
                >
                  General Instructions:
                </Text>
                <Text
                  style={{
                    width: "50%",
                    fontSize: 21,
                    fontWeight: 700,
                    textAlign: "right",
                  }}
                >
                  Maximum Marks:{test.maxMarks}
                </Text>
              </div>
              <Stack
                style={{
                  marginTop: "8px",
                  paddingLeft: "2px",
                }}
              >
                {instructions.map((x, i) => {
                  return (
                    <Text
                      style={{
                        fontFamily: "Nunito",
                        fontWeight: 400,
                        fontSize: 18,
                        margin: "3px 0px",
                      }}
                    >
                      {i + 1}. {x}
                    </Text>
                  );
                })}
              </Stack>
            </Stack>
          )}
          {!showInstructions && (
            <Text
              style={{
                width: "100%",
                fontSize: 21,
                fontWeight: 700,
                textAlign: "right",
                marginBottom: "-20px",
              }}
            >
              Maximum Marks:{test.maxMarks}
            </Text>
          )}

          <Box
            style={{
              marginLeft: 20,
              marginRight: 30,
              marginTop: inlineContent ? 20 : 50,
            }}
          >
            {test.questionType === QuestionType.AllQues.type && (
              <>
                {mcqQuestions.length > 0 && (
                  <>
                    <Text
                      style={{
                        textAlign: "center",
                        marginBottom: 30,
                        fontSize: 22,
                        fontWeight: 700,
                      }}
                    >
                      SECTION A- MULTIPLE CHOICE QUESTIONS
                    </Text>
                    {mcqQuestions.map((question, index) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            margin: "10px 0px",
                          }}
                        >
                          <Box
                            style={{
                              marginBottom: 20,
                              width: "95%",
                            }}
                          >
                            <Text>
                              {index + 1}.{question.text}
                            </Text>
                            {question.questionImageUrl && (
                              <img
                                src={question.questionImageUrl}
                                width={"25%"}
                              ></img>
                            )}
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                              }}
                            >
                              {question.answers.map(
                                (answer: any, index: number) => (
                                  <div
                                    key={index} // Add a key prop to each child element for React to identify them
                                    style={{
                                      margin: "5px 0px",
                                    }}
                                  >
                                    <div style={{ marginLeft: 10 }}>
                                      {lettering[index]}
                                      {") "}
                                      {`${answer.text}`}
                                    </div>
                                    {question.answerImageUrl.length > 0 && (
                                      <>
                                        {question.answerImageUrl[index].length >
                                          0 && (
                                          <img
                                            src={question.answerImageUrl[index]}
                                            width={"15%"}
                                            alt={`Answer ${index + 1}`}
                                          />
                                        )}
                                      </>
                                    )}
                                  </div>
                                )
                              )}
                            </div>
                          </Box>
                          <p
                            style={{
                              width: "5%",
                              marginTop: 0,
                            }}
                          >
                            {(test.maxMarks / test.maxQuestions).toFixed(2)}
                          </p>
                        </div>
                      );
                    })}
                  </>
                )}
                {shortQuestions.length > 0 && (
                  <>
                    <Text
                      style={{
                        textAlign: "center",
                        marginBottom: 30,
                        fontSize: 22,
                        fontWeight: 700,
                      }}
                    >
                      SECTION B- SHORT ANS. QUESTIONS
                    </Text>
                    {shortQuestions.map((question, index) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <Box
                            style={{
                              marginBottom: 20,
                              width: "95%",
                              display: "flex",
                            }}
                          >
                            <Text>
                              {index + 1 + mcqQuestions.length}.{question.text}
                            </Text>
                            {question.questionImageUrl && (
                              <img
                                src={question.questionImageUrl}
                                width={"50%"}
                              ></img>
                            )}
                          </Box>
                          <p
                            style={{
                              width: "5%",
                              marginTop: 0,
                            }}
                          >
                            {(test.maxMarks / test.maxQuestions).toFixed(2)}
                          </p>
                        </div>
                      );
                    })}
                  </>
                )}
                {longQuestions.length > 0 && (
                  <>
                    <Text
                      style={{
                        textAlign: "center",
                        marginBottom: 30,
                        fontSize: 22,
                        fontWeight: 700,
                      }}
                    >
                      SECTION C- LONG ANS. QUESTIONS
                    </Text>
                    {longQuestions.map((question, index) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <Box
                            style={{
                              marginBottom: 20,
                              width: "95%",
                              display: "flex",
                            }}
                          >
                            <Text>
                              {index +
                                1 +
                                shortQuestions.length +
                                mcqQuestions.length}
                              .{question.text}
                            </Text>
                            {question.questionImageUrl && (
                              <img
                                src={question.questionImageUrl}
                                width={"50%"}
                              ></img>
                            )}
                          </Box>
                          <p
                            style={{
                              width: "5%",
                              marginTop: 0,
                            }}
                          >
                            {(test.maxMarks / test.maxQuestions).toFixed(2)}
                          </p>
                        </div>
                      );
                    })}
                  </>
                )}
                {caseBasedQuestions.length > 0 && (
                  <>
                    <Text
                      style={{
                        textAlign: "center",
                        marginBottom: 30,
                        fontSize: 22,
                        fontWeight: 700,
                      }}
                    >
                      SECTION D- CASE BASED ANS. QUESTIONS
                    </Text>
                    {caseBasedQuestions.map((question1, index) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <Box
                            style={{
                              marginBottom: 20,
                              width: "95%",
                            }}
                          >
                            <Text>
                              {index +
                                1 +
                                shortQuestions.length +
                                mcqQuestions.length +
                                longQuestions.length}
                              .{question1.caseStudyText}
                            </Text>
                            {question1.questionImageUrl && (
                              <img
                                src={question1.questionImageUrl}
                                width={"25%"}
                              ></img>
                            )}

                            {question1.questions.map((question, index) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    margin: "10px 0px",
                                  }}
                                >
                                  <Box
                                    style={{
                                      marginBottom: 20,
                                      width: "95%",
                                    }}
                                  >
                                    {question.questionImageUrl && (
                                      <img
                                        src={question.questionImageUrl}
                                        width={"25%"}
                                      ></img>
                                    )}
                                    <Text>
                                      {index + 1}.{question.text}
                                    </Text>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      {question.answers.map(
                                        (answer: any, index: number) => {
                                          return (
                                            <div
                                              style={{
                                                display: "flex",
                                                margin: "5px 0px",
                                              }}
                                            >
                                              <Text style={{ marginLeft: 10 }}>
                                                {lettering[index]}
                                                {") "}
                                                {answer.text}
                                              </Text>
                                              {question.answerImageUrl.length >
                                                0 && (
                                                <>
                                                  {question.answerImageUrl[
                                                    index
                                                  ].length > 0 && (
                                                    <img
                                                      src={
                                                        question.answerImageUrl[
                                                          index
                                                        ]
                                                      }
                                                      width={"15%"}
                                                    ></img>
                                                  )}
                                                </>
                                              )}
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </Box>
                                </div>
                              );
                            })}
                          </Box>
                        </div>
                      );
                    })}
                  </>
                )}
              </>
            )}
            {test.questionType !== QuestionType.AllQues.type && (
              <>
                {test.questionType === QuestionType.LongQues.type && (
                  <Text
                    style={{
                      fontSize: 22,
                      fontWeight: 700,
                      marginTop: 25,
                      textAlign: "center",
                      marginBottom: 30,
                    }}
                  >
                    LONG ANS. QUESTIONS
                  </Text>
                )}
                {test.questionType === QuestionType.McqQues.type && (
                  <Text
                    style={{
                      fontSize: 22,
                      fontWeight: 700,
                      marginTop: 25,
                      textAlign: "center",
                      marginBottom: 30,
                    }}
                  >
                    MULTIPLE CHOICE QUESTIONS
                  </Text>
                )}
                {test.questionType === QuestionType.ShortQues.type && (
                  <Text
                    style={{
                      fontSize: 22,
                      fontWeight: 700,
                      marginTop: 25,
                      textAlign: "center",
                      marginBottom: 30,
                    }}
                  >
                    SHORT ANS. QUESTIONS
                  </Text>
                )}
                {test.questionType === QuestionType.CaseQues.type && (
                  <Text
                    style={{
                      fontSize: 22,
                      fontWeight: 700,
                      marginTop: 25,
                      textAlign: "center",
                      marginBottom: 30,
                    }}
                  >
                    CASE BASED ANS. QUESTIONS
                  </Text>
                )}
                {test.questions.map((question, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        margin: "10px 0px",
                      }}
                    >
                      <Box
                        style={{
                          marginBottom: 20,
                          width: "95%",
                        }}
                      >
                        <Text>
                          {index + 1}.{question.text}
                        </Text>
                        {question.questionImageUrl && (
                          <img
                            src={question.questionImageUrl}
                            width={"25%"}
                          ></img>
                        )}
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                          }}
                        >
                          {question.answers.map(
                            (answer: any, index: number) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    margin: "5px 0px",
                                  }}
                                >
                                  <Text style={{ marginLeft: 10 }}>
                                    {lettering[index]}
                                    {") "}
                                    {answer.text}
                                  </Text>
                                  {question.answerImageUrl.length > 0 && (
                                    <>
                                      {question.answerImageUrl[index].length >
                                        0 && (
                                        <img
                                          src={question.answerImageUrl[index]}
                                          width={"15%"}
                                        ></img>
                                      )}
                                    </>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </Box>
                      <p
                        style={{
                          width: "5%",
                          marginTop: 0,
                        }}
                      >
                        {(test.maxMarks / test.maxQuestions).toFixed(2)}
                      </p>
                    </div>
                  );
                })}
                {test.subjectiveQuestions.map((question, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <Box
                        style={{
                          marginBottom: 20,
                          width: "95%",
                          display: "flex",
                        }}
                      >
                        <Text>
                          {index + 1}.{question.text}
                        </Text>
                        {question.questionImageUrl && (
                          <img
                            src={question.questionImageUrl}
                            width={"50%"}
                          ></img>
                        )}
                      </Box>
                      <p
                        style={{
                          width: "5%",
                          marginTop: 0,
                        }}
                      >
                        {(test.maxMarks / test.maxQuestions).toFixed(2)}
                      </p>
                    </div>
                  );
                })}
                {test.casebasedquestions.map((question1, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <Box
                        style={{
                          marginBottom: 20,
                          width: "95%",
                        }}
                      >
                        <Text>
                          {index +
                            1 +
                            shortQuestions.length +
                            mcqQuestions.length}
                          .{question1.caseStudyText}
                        </Text>
                        {question1.questionImageUrl && (
                          <img
                            src={question1.questionImageUrl}
                            width={"25%"}
                          ></img>
                        )}

                        {question1.questions.map((question, index) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                margin: "10px 0px",
                              }}
                            >
                              <Box
                                style={{
                                  marginBottom: 20,
                                  width: "95%",
                                }}
                              >
                                {question.questionImageUrl && (
                                  <img
                                    src={question.questionImageUrl}
                                    width={"25%"}
                                  ></img>
                                )}
                                <Text>
                                  {index + 1}.{question.text}
                                </Text>
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  {question.answers.map(
                                    (answer: any, index: number) => {
                                      return (
                                        <div
                                          style={{
                                            display: "flex",
                                            margin: "5px 0px",
                                          }}
                                        >
                                          <Text style={{ marginLeft: 10 }}>
                                            {lettering[index]}
                                            {") "}
                                            {answer.text}
                                          </Text>
                                          {question.answerImageUrl.length >
                                            0 && (
                                            <>
                                              {question.answerImageUrl[index]
                                                .length > 0 && (
                                                <img
                                                  src={
                                                    question.answerImageUrl[
                                                      index
                                                    ]
                                                  }
                                                  width={"15%"}
                                                ></img>
                                              )}
                                            </>
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </Box>
                            </div>
                          );
                        })}
                      </Box>
                    </div>
                  );
                })}
              </>
            )}
          </Box>
        </body>
      </html>
    </>
  );
}
function createHtmlFromSampleTestData(
  test: FullTest,
  name: string,
  iconUrl: string,
  subHeading: string | null,
  banner: string | null,
  subscriptionModelType: string
  // phoneNumber:string
) {
  const lettering = ["a", "b", "c", "d", "e"];
  const instructions = [];
  const mcqQuestions = [];
  const longQuestions = [];
  const shortQuestions = [];
  const veryshortQuestions = [];
  const caseBasedQuestions = [];
  let lastQuestionNo = 1;
  instructions.push(
    `The Test paper has ${test.maxQuestions} questions in total.`
  );
  if (
    test.questionType === QuestionType.AllQues.type ||
    test.questionType === QuestionType.SamplePaper.type
  ) {
    mcqQuestions.push(...test.questions);
    const veryshortAnsQuestions = test.subjectiveQuestions.filter(
      (x) => x.type === QuestionType.VeryShortQues.type
    );
    const shortAnsQuestions = test.subjectiveQuestions.filter(
      (x) => x.type === QuestionType.ShortQues.type
    );
    const longAnsQuestions = test.subjectiveQuestions.filter(
      (x) => x.type === QuestionType.LongQues.type
    );
    caseBasedQuestions.push(...test.casebasedquestions);

    longQuestions.push(...longAnsQuestions);
    shortQuestions.push(...shortAnsQuestions);
    veryshortQuestions.push(...veryshortAnsQuestions);

    if (test.questions.length > 0) {
      instructions.push(
        `Questions From ${lastQuestionNo} to ${
          lastQuestionNo + test.questions.length - 1
        } are Objective Type Questions.`
      );
      lastQuestionNo += test.questions.length;
    }
    if (veryshortAnsQuestions.length > 0) {
      instructions.push(
        `Questions From ${lastQuestionNo} to ${
          lastQuestionNo + veryshortAnsQuestions.length - 1
        } are Very Short Type Questions.`
      );
      lastQuestionNo += veryshortAnsQuestions.length;
    }
    if (shortAnsQuestions.length > 0) {
      instructions.push(
        `Questions From ${lastQuestionNo} to ${
          lastQuestionNo + shortAnsQuestions.length - 1
        } are Short Answer Type Questions.`
      );
      lastQuestionNo += shortAnsQuestions.length;
    }
    if (caseBasedQuestions.length > 0) {
      instructions.push(
        `Questions From ${lastQuestionNo} to ${
          lastQuestionNo + caseBasedQuestions.length - 1
        } are Case Based Answer Type Questions.`
      );
      lastQuestionNo += caseBasedQuestions.length;
    }
    if (longAnsQuestions.length > 0) {
      instructions.push(
        `Questions From ${lastQuestionNo} to ${
          lastQuestionNo + longAnsQuestions.length + 1
        } are Long Answer Type Questions.`
      );
      lastQuestionNo += longAnsQuestions.length;
    }

    instructions.push(
      `Ensure that your answers are legible and properly marked.`
    );
  }
  instructions.push(
    `Follow the specified procedure for submitting your answer sheets or online responses.`
  );
  if (test.questionType === QuestionType.McqQues.type) {
    mcqQuestions.push(...test.questions);
    instructions.push(
      `Ensure that your answers are legible and properly marked.`
    );
  }
  if (test.questionType === QuestionType.LongQues.type) {
    const longAnsQuestions = test.subjectiveQuestions.filter(
      (x) => x.type === QuestionType.LongQues.type
    );
    longQuestions.push(...longAnsQuestions);
    instructions.push(`Ensure that your answers are legible.`);
  }
  if (test.questionType === QuestionType.ShortQues.type) {
    const shortAnsQuestions = test.subjectiveQuestions.filter(
      (x) => x.type === QuestionType.ShortQues.type
    );
    shortQuestions.push(...shortAnsQuestions);
    instructions.push(`Ensure that your answers are legible.`);
  }
  return (
    <html>
      <head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,400;1,700&display=swap"
          rel="stylesheet"
        />

        <script
          id="MathJax-script"
          defer
          src="https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-chtml.js"
        ></script>
      </head>
      <body style={{ fontFamily: "Comic Neue, sans-serif" }}>
        {subscriptionModelType === "PREMIUM" && banner !== null && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
            }}
          >
            <img width={200} src={banner}></img>
          </div>
        )}
        <p
          style={{
            marginTop: 10,
            fontWeight: 700,
            fontSize: 30,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {banner === null && <img width={35} src={iconUrl}></img>}
          <span style={{ marginRight: 10, marginLeft: 10 }}>
            {name.toUpperCase()}
          </span>
        </p>
        {subHeading !== null && (
          <p
            style={{
              fontSize: 16,
              fontWeight: 400,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: -25,
              color: "gray",
            }}
          >
            {subHeading}
          </p>
        )}
        {/* {subscriptionModelType !== "PREMIUM" && (
            <p
              style={{
                fontSize: 16,
                fontWeight: 400,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: -25,
                color: "blue",
              }}
            >
              Contact us at: www.vignam.in
            </p>
          )} */}
        <p
          style={{
            fontSize: 25,
            fontWeight: 700,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "5px 0px",
          }}
        >
          {test.name.toUpperCase()}
        </p>
        <Stack
          style={{
            border: "#4B65F6 solid 2px",
            borderRadius: "12px",
            padding: "10px",
          }}
          px={20}
          pb={10}
          pt={15}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                width: "50%",
                fontSize: 21,
                fontWeight: 700,
              }}
            >
              General Instructions:
            </Text>
            <Text
              style={{
                width: "50%",
                fontSize: 21,
                fontWeight: 700,
                textAlign: "right",
              }}
            >
              Maximum Marks:{test.maxMarks}
            </Text>
          </div>
          <Stack
            style={{
              marginTop: "8px",
              paddingLeft: "2px",
            }}
          >
            {instructions.map((x, i) => {
              return (
                <Text
                  style={{
                    fontFamily: "Nunito",
                    fontWeight: 400,
                    fontSize: 18,
                    margin: "3px 0px",
                  }}
                >
                  {i + 1}. {x}
                </Text>
              );
            })}
          </Stack>
        </Stack>
        <Box style={{ marginLeft: 20, marginRight: 30, marginTop: 50 }}>
          {(test.questionType === QuestionType.AllQues.type ||
            test.questionType === QuestionType.SamplePaper.type) && (
            <>
              {mcqQuestions.length > 0 && (
                <>
                  <Text
                    style={{
                      textAlign: "center",
                      marginBottom: 30,
                      fontSize: 22,
                      fontWeight: 700,
                    }}
                  >
                    SECTION A- MULTIPLE CHOICE QUESTIONS
                  </Text>
                  {mcqQuestions.map((question, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          margin: "10px 0px",
                        }}
                      >
                        <Box
                          style={{
                            marginBottom: 20,
                            width: "95%",
                          }}
                        >
                          <Text>
                            {index + 1}.{`${question.text}`}
                          </Text>
                          {question.questionImageUrl && (
                            <img
                              src={question.questionImageUrl}
                              width={"25%"}
                            ></img>
                          )}

                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "space-between",
                            }}
                          >
                            {question.answers.map(
                              (answer: any, index: number) => {
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      margin: "5px 0px",
                                    }}
                                  >
                                    <Text style={{ marginLeft: 10 }}>
                                      {lettering[index]}
                                      {") "}
                                      {`${answer.text}`}
                                    </Text>
                                    {question.answerImageUrl.length > 0 && (
                                      <>
                                        {question.answerImageUrl[index].length >
                                          0 && (
                                          <img
                                            src={question.answerImageUrl[index]}
                                            width={"15%"}
                                          ></img>
                                        )}
                                      </>
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </Box>
                        <p
                          style={{
                            width: "5%",
                            marginTop: 0,
                          }}
                        >
                          1
                        </p>
                      </div>
                    );
                  })}
                </>
              )}
              {veryshortQuestions.length > 0 && (
                <>
                  <Text
                    style={{
                      textAlign: "center",
                      marginBottom: 30,
                      fontSize: 22,
                      fontWeight: 700,
                    }}
                  >
                    SECTION B- VERY SHORT ANS. QUESTIONS
                  </Text>
                  {veryshortQuestions.map((question, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <Box
                          style={{
                            marginBottom: 20,
                            width: "95%",
                            display: "flex",
                          }}
                        >
                          <Text>
                            {index + 1 + mcqQuestions.length}.
                            {`${question.text}`}
                          </Text>
                          {question.questionImageUrl && (
                            <img
                              src={question.questionImageUrl}
                              width={"50%"}
                            ></img>
                          )}
                        </Box>
                        <p
                          style={{
                            width: "5%",
                            marginTop: 0,
                          }}
                        >
                          2
                        </p>
                      </div>
                    );
                  })}
                </>
              )}
              {shortQuestions.length > 0 && (
                <>
                  <Text
                    style={{
                      textAlign: "center",
                      marginBottom: 30,
                      fontSize: 22,
                      fontWeight: 700,
                    }}
                  >
                    SECTION C- SHORT ANS. QUESTIONS
                  </Text>
                  {shortQuestions.map((question, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <Box
                          style={{
                            marginBottom: 20,
                            width: "95%",
                            display: "flex",
                          }}
                        >
                          <Text>
                            {index +
                              1 +
                              mcqQuestions.length +
                              veryshortQuestions.length}
                            .{`${question.text}`}
                          </Text>
                          {question.questionImageUrl && (
                            <img
                              src={question.questionImageUrl}
                              width={"50%"}
                            ></img>
                          )}
                        </Box>
                        <p
                          style={{
                            width: "5%",
                            marginTop: 0,
                          }}
                        >
                          3
                        </p>
                      </div>
                    );
                  })}
                </>
              )}
              {caseBasedQuestions.length > 0 && (
                <>
                  <Text
                    style={{
                      textAlign: "center",
                      marginBottom: 30,
                      fontSize: 22,
                      fontWeight: 700,
                    }}
                  >
                    SECTION D- CASE BASED ANS. QUESTIONS
                  </Text>
                  {caseBasedQuestions.map((question1, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <Box
                          style={{
                            marginBottom: 20,
                            width: "95%",
                          }}
                        >
                          <Text>
                            {index +
                              1 +
                              veryshortQuestions.length +
                              shortQuestions.length +
                              mcqQuestions.length}
                            .{question1.caseStudyText}
                          </Text>
                          {question1.questionImageUrl && (
                            <img
                              src={question1.questionImageUrl}
                              width={"25%"}
                            ></img>
                          )}

                          {question1.questions.map((question, index) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  margin: "10px 0px",
                                }}
                              >
                                <Box
                                  style={{
                                    marginBottom: 20,
                                    width: "95%",
                                  }}
                                >
                                  {question.questionImageUrl && (
                                    <img
                                      src={question.questionImageUrl}
                                      width={"25%"}
                                    ></img>
                                  )}
                                  <Text>
                                    {index + 1}.{question.text}
                                  </Text>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {question.answers.map(
                                      (answer: any, index: number) => {
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              margin: "5px 0px",
                                            }}
                                          >
                                            <Text style={{ marginLeft: 10 }}>
                                              {lettering[index]}
                                              {") "}
                                              {answer.text}
                                            </Text>
                                            {question.answerImageUrl.length >
                                              0 && (
                                              <>
                                                {question.answerImageUrl[index]
                                                  .length > 0 && (
                                                  <img
                                                    src={
                                                      question.answerImageUrl[
                                                        index
                                                      ]
                                                    }
                                                    width={"15%"}
                                                  ></img>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </Box>
                              </div>
                            );
                          })}
                        </Box>
                        <p
                          style={{
                            width: "5%",
                            marginTop: 0,
                          }}
                        >
                          4
                        </p>
                      </div>
                    );
                  })}
                </>
              )}
              {longQuestions.length > 0 && (
                <>
                  <Text
                    style={{
                      textAlign: "center",
                      marginBottom: 30,
                      fontSize: 22,
                      fontWeight: 700,
                    }}
                  >
                    SECTION E- LONG ANS. QUESTIONS
                  </Text>
                  {longQuestions.map((question, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <Box
                          style={{
                            marginBottom: 20,
                            width: "95%",
                            display: "flex",
                          }}
                        >
                          <Text>
                            {index +
                              1 +
                              shortQuestions.length +
                              mcqQuestions.length +
                              veryshortQuestions.length +
                              caseBasedQuestions.length}
                            .{`${question.text}`}
                          </Text>
                          {question.questionImageUrl && (
                            <img
                              src={question.questionImageUrl}
                              width={"50%"}
                            ></img>
                          )}
                        </Box>
                        <p
                          style={{
                            width: "5%",
                            marginTop: 0,
                          }}
                        >
                          5
                        </p>
                      </div>
                    );
                  })}
                </>
              )}
            </>
          )}
          {test.questionType !== QuestionType.AllQues.type &&
            test.questionType !== QuestionType.SamplePaper.type && (
              <>
                {test.questionType === QuestionType.LongQues.type && (
                  <Text
                    style={{
                      fontSize: 22,
                      fontWeight: 700,
                      marginTop: 25,
                      textAlign: "center",
                      marginBottom: 30,
                    }}
                  >
                    LONG ANS. QUESTIONS
                  </Text>
                )}
                {test.questionType === QuestionType.McqQues.type && (
                  <Text
                    style={{
                      fontSize: 22,
                      fontWeight: 700,
                      marginTop: 25,
                      textAlign: "center",
                      marginBottom: 30,
                    }}
                  >
                    MULTIPLE CHOICE QUESTIONS
                  </Text>
                )}
                {test.questionType === QuestionType.ShortQues.type && (
                  <Text
                    style={{
                      fontSize: 22,
                      fontWeight: 700,
                      marginTop: 25,
                      textAlign: "center",
                      marginBottom: 30,
                    }}
                  >
                    SHORT ANS. QUESTIONS
                  </Text>
                )}
                {test.questions.map((question, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        margin: "10px 0px",
                      }}
                    >
                      <Box
                        style={{
                          marginBottom: 20,
                          width: "95%",
                        }}
                      >
                        <Text>
                          {index + 1}.{question.text}
                        </Text>
                        {question.questionImageUrl && (
                          <img
                            src={question.questionImageUrl}
                            width={"25%"}
                          ></img>
                        )}
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                          }}
                        >
                          {question.answers.map(
                            (answer: any, index: number) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    margin: "5px 0px",
                                  }}
                                >
                                  <Text style={{ marginLeft: 10 }}>
                                    {lettering[index]}
                                    {") "}
                                    {answer.text}
                                  </Text>
                                  {question.answerImageUrl.length > 0 && (
                                    <>
                                      {question.answerImageUrl[index].length >
                                        0 && (
                                        <img
                                          src={question.answerImageUrl[index]}
                                          width={"15%"}
                                        ></img>
                                      )}
                                    </>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </Box>
                      <p
                        style={{
                          width: "5%",
                          marginTop: 0,
                        }}
                      >
                        {(test.maxMarks / test.maxQuestions).toFixed(2)}
                      </p>
                    </div>
                  );
                })}
                {test.subjectiveQuestions.map((question, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <Box
                        style={{
                          marginBottom: 20,
                          width: "95%",
                          display: "flex",
                        }}
                      >
                        <Text>
                          {index + 1}.{question.text}
                        </Text>
                        {question.questionImageUrl && (
                          <img
                            src={question.questionImageUrl}
                            width={"50%"}
                          ></img>
                        )}
                      </Box>
                      <p
                        style={{
                          width: "5%",
                          marginTop: 0,
                        }}
                      >
                        {(test.maxMarks / test.maxQuestions).toFixed(2)}
                      </p>
                    </div>
                  );
                })}
              </>
            )}
        </Box>
      </body>
    </html>
  );
}

const instituteWithInstructions = [
  "INST-8251030a-c36d-4e2b-a25f-7d6fe9a13734",
  // "INST-61707a41-a53f-4407-8552-c66aa36e1d50",
];
const instituteWithComicFont = [
  "INST-8251030a-c36d-4e2b-a25f-7d6fe9a13734",
  // "INST-61707a41-a53f-4407-8552-c66aa36e1d50",
];
const instituteWithWaterMark = [
  { id: "INST-8251030a-c36d-4e2b-a25f-7d6fe9a13734", waterMarkText: "KCMS" },
  // { id: "INST-61707a41-a53f-4407-8552-c66aa36e1d50", waterMarkText: "KCMS" },
];
const instituteWithBorder = [
  "INST-8251030a-c36d-4e2b-a25f-7d6fe9a13734",
  // "INST-61707a41-a53f-4407-8552-c66aa36e1d50",
];
const inlineFormat = [
  "INST-8251030a-c36d-4e2b-a25f-7d6fe9a13734",
  // "INST-61707a41-a53f-4407-8552-c66aa36e1d50",
];
export function downloadPdf(
  testId: string,
  setIsLoading: (input: boolean) => void,
  user1: User1 | null,
  isReactNativeActive: boolean,
  sendDataToReactnative: (command: number, data: any) => void,
  isSolutions: boolean,
  isBorder: boolean,
  isInstructions: boolean,
  waterMarkText: string | null
) {
  Mixpanel.track(WebAppEvents.TEACHER_APP_TEST_PAGE_DOWNLOAD_BUTTON_CLICKED);
  setIsLoading(true);

  let instituteName: string,
    instituteLogo: string,
    instituteBanner: string | null,
    instituteSubheading: string | null;
  const user = GetUser();
  const subscriptionModelType = user1?.subscriptionModelType;
  GetAllInfoForInstitute({ id: user.instituteId })
    .then((x: any) => {
      instituteName = x.name;
      instituteLogo = x.schoolIcon;
      instituteBanner = x.schoolBanner;
      instituteSubheading = x.schoolSubText;

      fetchFullTest(testId)
        .then((data: any) => {
          if (data.isNewSectionType === true) {
            htmlToPdf({
              html: ReactDOMServer.renderToString(
                createNewSectionTypeHtmlFromTestData(
                  data,
                  instituteName,
                  instituteLogo,
                  instituteSubheading,
                  instituteBanner,
                  subscriptionModelType ?? "FREE",
                  isInstructions,
                  instituteWithComicFont.includes(user.instituteId),
                  inlineFormat.includes(user.instituteId),
                  x.institutePhoneNumber,
                  x.secondInstituteNumber,
                  x.Address,
                  isSolutions
                )
              ),
              showBorder: isBorder,
              showWaterMark: waterMarkText,
            })
              .then((data2: any) => {
                var link = document.createElement("a");
                const newBlob = new Blob([data2], { type: "application/pdf" });
                var file = new File([newBlob], "name");
                link.href = window.URL.createObjectURL(
                  new Blob([data2], { type: "application/pdf" })
                );
                link.download = `${data.name}.pdf`;
                if (isReactNativeActive) {
                  FileUpload({ file })
                    .then((x) => {
                      sendDataToReactnative(1, {
                        url: x.url,
                      });
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                } else link.click();
                link.remove();

                setIsLoading(false);
              })
              .catch((error) => {
                setIsLoading(false);
                console.error("Error:", error);
              });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    })
    .catch((e) => {
      console.log(e);
    });
}
