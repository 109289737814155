import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Grid,
  Image,
  NumberInput,
  Radio,
  Stack,
  Text,
  Textarea,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { DisplayHtmlText } from "../../../pages/_New/PersonalizedTestQuestions";
import { QuestionType } from "../../../@types/QuestionTypes.d";
import Keypad from "./Keypad";

export function QuestionCard(props: {
  topicName?: string | undefined;
  question: SubjectiveQuestion;
  number: number;
  onAnwerChange: (text: string) => void;
  onOptionSelect: (isSelected: boolean) => void;
  toggleMarkedForReview: (isOptionSelected: boolean) => void;
  onNextButtonClick: (isOptionSelected: boolean) => void;
  subjectiveAnswers: SubjectiveTestAnswer[];
}) {
  const isMd = useMediaQuery(`(max-width: 548px)`);

  const value =
    props.subjectiveAnswers.find((x) => x.question_id == props.question._id)
      ?.answerText || "";

  const clearResponse = () => {
    props.onAnwerChange("");
    props.onOptionSelect(false);
  };

  return (
    <>
      {!(props.topicName === null || props.topicName === undefined) && (
        <>
          <Text c={"#6B82BE"} fz={18} fw={500} ml={"2%"}>
            Chapter: {props.question.text}
          </Text>
          <Divider my={20} size={2} c={"#E5E7EB"} w={"100%"} />
        </>
      )}
      <Box ml={10}>
        <Grid>
          <Grid.Col span={11}>
            <Stack>
              <Flex justify="space-between">
                <Flex fz={18} fw={500}>
                  <Text>Q{`${props.number + 1}. `}</Text>
                  <DisplayHtmlText text={props.question.text} />
                </Flex>
              </Flex>
              {props.question.questionImageUrl && (
                <Image
                  src={props.question.questionImageUrl}
                  width={"50%"}
                ></Image>
              )}
              {props.question.type !== QuestionType.IntegerQues.type && (
                <Textarea
                  placeholder="Your Answer!"
                  value={value}
                  onChange={(event) => {
                    props.onAnwerChange(event.currentTarget.value);
                    props.onOptionSelect(
                      event.currentTarget.value.trim() !== ""
                    );
                  }}
                  rows={10}
                />
              )}
              {props.question.type === QuestionType.IntegerQues.type && (
                <>
                  <NumberInput
                    placeholder="Your Answer!"
                    value={value !== "" ? parseInt(value) : undefined}
                    onChange={(num) => {}}
                    contentEditable={false}
                    hideControls
                    readOnly
                  />
                  <Keypad
                    onKeyPress={(num: any) => {
                      props.onAnwerChange(num);
                      props.onOptionSelect(true);
                    }}
                  />
                </>
              )}
              <Divider size={2} />
              <Flex justify={"space-between"}>
                <Box>
                  <Button
                    onClick={clearResponse}
                    style={{
                      border: "1px solid #808080",
                      color: "black",
                      backgroundColor: "white",
                      cursor: "pointer",
                    }}
                  >
                    Clear Response
                  </Button>
                  <Button
                    ml={isMd ? 1 : 20}
                    mt={isMd ? 10 : 0}
                    onClick={() => {
                      props.toggleMarkedForReview(value.trim() !== "");
                    }}
                    style={{
                      border: "1px solid #808080",
                      color: "black",
                      backgroundColor: "white",
                      cursor: "pointer",
                    }}
                  >
                    Mark for Review
                  </Button>
                </Box>
                <Box>
                  <Button
                    onClick={() => {
                      props.onNextButtonClick(value.trim() !== "");
                    }}
                    style={{ border: "1px solid #808080", cursor: "pointer" }}
                  >
                    Save & Next
                  </Button>
                </Box>
              </Flex>
            </Stack>
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
}

export function CaseBasedQuestionCard(props: {
  _id: string;
  casebasedquestion: CaseBasedQuestion;
  number: number;
  onAnwerChange: (
    isCorrect: boolean,
    option: number,
    questionIndex: number
  ) => void;
  onOptionSelect: (isSelected: boolean) => void;
  toggleMarkedForReview: (isOptionSelected: boolean) => void;
  onNextButtonClick: (isOptionSelected: boolean) => void;
  caseStudyAnswers: CasestudyAnswers[];
}) {
  const selectedOption =
    props.caseStudyAnswers.find(
      (x) => x.question_id == props.casebasedquestion._id
    )?.options || [];

  function onAnswerChange(
    value: boolean,
    option: number,
    questionIndex: number
  ) {
    props.onAnwerChange(value, option, questionIndex);
    props.onOptionSelect(true);
  }
  const isMd = useMediaQuery(`(max-width: 548px)`);

  const clearResponse = () => {
    props.onAnwerChange(false, -1, 0);
    props.onOptionSelect(false);
  };

  return (
    <>
      <Box ml={8}>
        <Stack>
          <Flex justify="space-between">
            <Flex
              w={"50%"}
              style={{ borderRight: "2px solid black", paddingRight: "10px" }}
            >
              <Text>
                <DisplayHtmlText text={props.casebasedquestion.caseStudyText} />
              </Text>
            </Flex>
            <Stack w={"50%"} ml={20} mt={10}>
              {props.casebasedquestion.questions.map((y, i) => {
                return (
                  <>
                    <Flex justify="space-between" mr={5}>
                      <Flex>
                        <Text>Q{`${i + 1}. `}</Text>
                        <DisplayHtmlText text={y.text} />
                      </Flex>
                      <Text>
                        {y.totalMarks}
                        {" | -"}
                        {y.totalNegativeMarks}
                      </Text>
                    </Flex>
                    <Stack w={"100%"} mt={10}>
                      {y.questionImageUrl && (
                        <img src={y.questionImageUrl} width={"50%"} />
                      )}
                      {y.answers.map((x, index) => {
                        const isSelected = selectedOption[i] === index;
                        return (
                          <Flex
                            align="center"
                            onClick={() => {
                              const isCorrect = y.answers[index].isCorrect;
                              onAnswerChange(isCorrect, index, i);
                            }}
                            style={{
                              cursor: "pointer",
                              marginBottom: "10px",
                            }}
                            key={index}
                          >
                            <Box
                              style={{
                                width: "15px",
                                height: "15px",
                                border: isSelected
                                  ? "1px solid #4B65F6"
                                  : "1px solid #2E2200",
                                borderRadius: "50%",
                                background: "white",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {isSelected && (
                                <Box
                                  style={{
                                    width: "8px",
                                    height: "8px",
                                    borderRadius: "50%",
                                    background: isSelected
                                      ? "#4B65F6"
                                      : "transparent",
                                  }}
                                ></Box>
                              )}
                            </Box>
                            <Text fz={16} fw={500} ml={12}>
                              <DisplayHtmlText text={x.text} />
                            </Text>
                            {y.answerImageUrl[index] && (
                              <img
                                src={y.answerImageUrl[index]}
                                width={"50%"}
                              />
                            )}
                          </Flex>
                        );
                      })}
                    </Stack>
                  </>
                );
              })}
            </Stack>
          </Flex>
          <Divider size={2} />
          <Flex justify={"space-between"}>
            <Box>
              <Button
                onClick={clearResponse}
                style={{
                  border: "1px solid #808080",
                  color: "black",
                  backgroundColor: "white",
                  cursor: "pointer",
                }}
              >
                Clear Response
              </Button>
              <Button
                ml={isMd ? 1 : 20}
                mt={isMd ? 10 : 0}
                onClick={() => {
                  props.toggleMarkedForReview(
                    selectedOption.filter((x) => x !== -1).length > 0 &&
                      selectedOption.length > 0
                  );
                }}
                style={{
                  border: "1px solid #808080",
                  color: "black",
                  backgroundColor: "white",
                  cursor: "pointer",
                }}
              >
                Mark for Review
              </Button>
            </Box>
            <Box>
              <Button
                onClick={() => {
                  props.onNextButtonClick(
                    selectedOption.filter((x) => x !== -1).length > 0 &&
                      selectedOption.length > 0
                  );
                }}
                style={{ border: "1px solid #808080", cursor: "pointer" }}
              >
                Save & Next
              </Button>
            </Box>
          </Flex>
        </Stack>
      </Box>
    </>
  );
}

export function MCQuestionCard(props: {
  question: McqQuestion;
  number: number;
  onAnwerChange: (option: number | null) => void;
  onOptionSelect: (isSelected: boolean) => void;
  toggleMarkedForReview: (isOptionSelected: boolean) => void;
  onNextButtonClick: (isOptionSelected: boolean) => void;
  mcqAnswers: MCQTestAnswer[];
}) {
  const isMd = useMediaQuery(`(max-width: 548px)`);

  const selectedAnswer = props.mcqAnswers.find(
    (answer) => answer.question_id === props.question._id
  );
  const selectedOption = selectedAnswer ? selectedAnswer.options : [];

  const clearResponse = () => {
    props.onAnwerChange(null);
    props.onOptionSelect(false);
  };

  return (
    <>
      <Box ml={8}>
        <Stack>
          <Flex justify="space-between">
            <Flex fz={18} fw={500}>
              <Text>Q{`${props.number + 1}.`}</Text>
              <DisplayHtmlText text={props.question.text} />
            </Flex>
          </Flex>
          {props.question.answers.map((x, index) => {
            const isSelected = selectedOption.includes(index);
            return (
              <Flex align="center">
                {props.question.type !== QuestionType.MultiCorrectQues.type && (
                  <Radio
                    checked={isSelected}
                    onChange={() => {
                      props.onOptionSelect(true);
                      props.onAnwerChange(index);
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                )}
                {props.question.type === QuestionType.MultiCorrectQues.type && (
                  <Checkbox
                    checked={isSelected}
                    onChange={() => {
                      props.onOptionSelect(true);
                      props.onAnwerChange(index);
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                )}
                <Text fz={16} fw={500} ml={12}>
                  <DisplayHtmlText text={x.text} />
                </Text>
              </Flex>
            );
          })}
          <Divider size={2} />
          <Flex justify={"space-between"}>
            <Box>
              <Button
                onClick={clearResponse}
                style={{
                  border: "1px solid #808080",
                  color: "black",
                  backgroundColor: "white",
                  cursor: "pointer",
                }}
              >
                Clear Response
              </Button>
              <Button
                ml={isMd ? 1 : 20}
                mt={isMd ? 10 : 0}
                onClick={() => {
                  props.toggleMarkedForReview(selectedOption.length > 0);
                }}
                style={{
                  border: "1px solid #808080",
                  color: "black",
                  backgroundColor: "white",
                  cursor: "pointer",
                }}
              >
                Mark for Review
              </Button>
            </Box>
            <Box>
              <Button
                onClick={() => {
                  props.onNextButtonClick(selectedOption.length > 0);
                }}
                style={{ border: "1px solid #808080", cursor: "pointer" }}
              >
                Save & Next
              </Button>
            </Box>
          </Flex>
        </Stack>
      </Box>
    </>
  );
}
